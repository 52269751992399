@font-face {
  font-family: 'Brother';
  font-style: normal;
  src: url('./fonts/Brother1816Printed-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Metallophile';
  font-style: normal;
  src: url('./fonts/Metallophile Sp8 Medium.ttf') format('truetype');
}

@keyframes scan {
  from {
    transform: translateY(calc(100% * -1));
  }
  100% {
    transform: translateY(100vh);
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.big-text {
  font-family: 'Brother';
  font-weight: 800;
  font-size: max(min(calc(60 * calc(10vw / 151)), 60pt), 20pt);
  margin-left: max(8vw, 20pt);
  margin-right: max(8vw, 20pt);
  margin-top: 0;
  margin-bottom: 40pt;
  line-height: 100%;
  text-align: center;
}

.overlay {
  width: 100%;
}

.flipped {
  transform: scale(-1, 1);
}

button {
  font-family: 'Brother1816';
}

#root {
  height: 100%;
}

.page-container {
  /* height: 100vh; */
  width: 100vw;
  /* height: 100svh; */
  width: 100svw;
  height: 100%;
  box-sizing: border-box;
  padding-top: max(8vh, 30pt);
  padding-bottom: 0pt;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

footer {
  height: max(8vh, 30pt);
}

.flat-button {
  border: 2px white solid;
  background-color: black;
  color: white;
  font-size: max(min(calc(45 * calc(10vw / 151)), 45pt), 20pt);
  font-family:'Metallophile';
  width: min(350pt, 80vw);
  border-radius: 6pt;
  box-sizing: border-box;
  padding: 6pt 0pt;
}
.snapshot {
  max-height: 60vh;
  width:100%;
}

.instructions {
  font-family: 'Brother';
  font-size: 18pt;
  margin-left: max(8vw, 20pt);
  margin-right: max(8vw, 20pt);
  margin-top: 0;
  margin-bottom: 0;
  line-height: 120%;
  text-align: center;
}

.flat-button:active {
  background-color: white;
  color: black;
}

.centered {
  flex-direction: column;
  display: flex;
  align-items: center;
  height: 100%;
}

.justify-centered {
  justify-content: center;
}

.scan-container {
  animation-name: scan;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  width:100%;
}

a {
  color: #C7903E;
}

a:active {
  color: #84602a;
}

.link-button {
  border: none;
  background: none;
  color: #C7903E;
  font-family: 'Brother';
  font-size: 12pt;
  cursor: pointer;
  text-decoration: underline;
}

.link-button:active {
  color: #84602a;
}

.bottom {
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
}

.no-select {
  user-select: none;
  -webkit-user-drag: none;
}

.handle {
  width: 20pt;
  height: 20pt;
}

.snapshot-container{
  max-width: 84vw;
  margin-left: max(8vw, 20pt);
  margin-right: max(8vw, 20pt);
  box-sizing: border-box;
}

.overlay-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}